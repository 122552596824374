<template lang="">
  <div class="container">
    <SubHeader :pathList="pathList" :title="title" />

    <div id="contents" class="contents">
      <div class="title-sort-box">
        <h3>{{ $t('qna.register-title') }}</h3>
        <p class="red-txt" v-html="$t('common.required-input-guide')"></p>
      </div>
      <!--/title-sort-box-->
      <div class="table-horizon st01">
        <ValidationObserver ref="form">
          <form enctype="multipart/form-data">
            <table class="form-md">
              <colgroup>
                <col style="width:7%" />
                <col style="width:40%" />
                <col style="width:13%" />
                <col style="width:40%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <label class="ic-star">{{ $t('qna.label-title') }}</label>
                  </th>
                  <td>
                    <text-input
                      rules="required"
                      label="title"
                      name="questSj"
                      maxlength="150"
                      v-model="formData.questSj"
                    />
                  </td>

                  <th>
                    <label class="ic-star">{{ $t('qna.label-email') }}</label>
                  </th>
                  <td>
                    <text-input
                      :rules="{
                        required: true,
                        regex: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
                      }"
                      label="E-mail"
                      name="qstnerEmail"
                      maxlength="100"
                      dataType="EMAIL"
                      v-model.trim="formData.qstnerEmail"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <label class="ic-star">{{ $t('qna.label-name') }}</label>
                  </th>
                  <td>
                    <text-input
                      rules="required"
                      label="Name"
                      name="qstnerNm"
                      maxlength="10"
                      v-model.trim="formData.qstnerNm"
                    />
                  </td>

                  <th>
                    <label class="ic-star">{{ $t('qna.label-tel') }}</label>
                  </th>
                  <td>
                    <text-input
                      :rules="{ required: true, regex: /^\d{11,11}$/ }"
                      label="Tel."
                      name="qstnerTlnum"
                      maxlength="11"
                      dataType="NUMBER"
                      v-model.trim="formData.qstnerTlnum"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <label class="ic-star">{{
                      $t('qna.label-contents')
                    }}</label>
                  </th>
                  <td colspan="3">
                    <text-input
                      rules="required"
                      label="Contents"
                      name="questCn"
                      maxlength="1500"
                      type="textarea"
                      v-model="formData.questCn"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('qna.label-password') }}</th>
                  <td>
                    <text-input
                      label="Password"
                      type="password"
                      name="password"
                      maxlength="10"
                      v-model.trim="formData.password"
                    />
                  </td>
                  <th>&nbsp;</th>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </form>
        </ValidationObserver>
      </div>
      <div class="btns-bottom">
        <button type="button" class="bt-md bt-default" @click="resetForm">
          <span>{{ $t('common.btn-cancel') }}</span>
        </button>
        <button type="button" class="bt-md bt-red" @click="checkValidate">
          <span>{{ $t('common.btn-submit') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import TextInput from '@/components/common/TextInput';
import { insertQna } from '@/api/qnaApi.js';
export default {
  components: { TextInput },
  data() {
    return {
      pathList: [
        this.$i18n.t('menu.home'),
        this.$i18n.t('menu.support'),
        this.$i18n.t('subMenu.qna'),
      ],
      title: {
        mainTitle: this.$i18n.t('menu.support'),
        smallTitle: this.$i18n.t('menu.support-eng'),
      },
      formData: {
        questSj: '',
        qstnerEmail: '',
        qstnerNm: '',
        qstnerTlnum: '',
        questCn: '',
        password: '',
      },
    };
  },
  methods: {
    // 페이지 유효성 검사
    checkValidate() {
      this.$refs.form.validate().then(res => {
        if (!res) {
          this.$dialogs.alert(this.$i18n.t('common.invalid-required'));
          return;
        }
        this.insertQna();
      });
    },
    // 문의사항 등록
    async insertQna() {
      let formData = this.formData;
      const {
        data: { valid },
      } = await insertQna(formData);
      if (valid) {
        this.$dialogs
          .alert(this.$i18n.t('common.alert-submit-success'))
          .then(res => {
            if (res) {
              this.$router.push('/qna');
            }
          });
      } else {
        this.$dialogs.alert(this.$i18n.t('common.alert-submit-fail'));
      }
    },
    // 폼 초기화
    resetForm() {
      this.$refs.form.reset();
      // i18n 적용 이후 에러 발생하여 주석 처리
      // Object.assign(this.$data, this.$options.data());
      this.$router.push('/qna');
    },
  },
};
</script>
<style lang=""></style>
